import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "iconography",
      "style": {
        "position": "relative"
      }
    }}>{`Iconography`}</h1>
    <TabLinks items={[{
      title: 'Usage',
      href: '/design-guidelines/iconography'
    }, {
      title: 'Library',
      href: '/design-guidelines/iconography/library'
    }]} mdxType="TabLinks" />
    <p>{`Icons represent objects, actions, and states, and are used to communicate a message. Each icon is designed to
be simple, friendly and reduced to its minimal form, expressing essential characteristics.`}</p>
    <h2 {...{
      "id": "icon-grid",
      "style": {
        "position": "relative"
      }
    }}>{`Icon Grid`}</h2>
    <p>{`The icon grid establishes clear rules for the consistent, but flexible, positioning of the graphic elements.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/icn-canvas.png",
        "alt": "Canvas size, padding and live area"
      }}></img></p>
    <h2 {...{
      "id": "size-and-weight",
      "style": {
        "position": "relative"
      }
    }}>{`Size and weight`}</h2>
    <p>{`All icons are built on a 24px square with 2px of padding. System icons use a consistent stroke width of 2px,
including curves and angles. When using icons, all touch target need to be 40px or higher. Icons of the same
size should have the same visual weight. One icon should not look heavier or lighter than another icon of the
same size. Glyphs or 16px icons should always be a filled icon, this assures that the icon stays legible.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/icn-size.png",
        "alt": "Button size and Glyph"
      }}></img></p>
    <h2 {...{
      "id": "keyline-shapes",
      "style": {
        "position": "relative"
      }
    }}>{`Keyline shapes`}</h2>
    <p>{`By using the core shapes based on the grid, you can maintain consistent visual proportions throughout your
icons.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/icn-keyline.png",
        "alt": "Canvas size, padding and live area"
      }}></img></p>
    <h3 {...{
      "id": "pixel-perfection",
      "style": {
        "position": "relative"
      }
    }}>{`Pixel perfection`}</h3>
    <p>{`To avoid distorting an icon, position icons “on pixel” by making the X and Y coordinates into integers,
without decimals.`}</p>
    <h3 {...{
      "id": "examples",
      "style": {
        "position": "relative"
      }
    }}>{`Examples`}</h3>
    <p><img parentName="p" {...{
        "src": "/image/icn-pixel.png",
        "alt": "Icon examples"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      